/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  font-family: "Inter", serif !important;
}
h1,h2,h3,h4,h5,h6,p,span,tr,th,td{
  font-family: "Inter", serif !important;
}

option {
  color: black;
}

* {
  letter-spacing: 0.3px;
  outline: none;
}

::-webkit-scrollbar {
  height: 4px !important;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 20px;
}

.css-70fard,
.apexcharts-toolbar {
  z-index: 0 !important;
}

.fc-bg-event {
  opacity: 1 !important;
}

.p0 {
  margin-top: 0;
}

/* TABLE  */
.table-container {
  min-height: 23vh;
  max-height: 23vh;
  overflow-y: auto;
}

.table-container thead {
  border-bottom: 1px solid black;
  box-shadow: -2px 0px 4px -2px #5c5d5efa;
  position: sticky;
  top: 0;
  height: 25px;
  background: #ffffff;
}

.table-container-property {
  min-height: auto;
  max-height: 68vh;
  overflow-y: auto;
}

.css-efk3t2 {
  max-height: 15rem !important;
  overflow-y: scroll !important;
}

.table-container-property thead {
  position: sticky;
  top: 0;
  height: 25px;
  background: #ffffff;
}
.signin-box{
  height: 100vh;
  overflow-y: scroll;
}
.signin-box::-webkit-scrollbar {
  display: none;
}
.signin-box {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.table-fix-container {
  min-height: 63vh;
  max-height: 63vh;
  overflow-y: auto;
}
.small-table-fix-container {
  min-height: 15vh;
  max-height: 63vh;
  overflow-y: auto;
}

.table-fix-container thead {
  position: sticky;
  top: 0;
  height: 25px;
  background: #F3F6F9;
}

.importTable {
  min-height: auto;
  max-height: 82vh;
  overflow-y: auto;
}

.tableData {
  padding: 10px !important;
}

/* .leadRow td {
  border-bottom: 1px solid #e2e8f0;
} */

.datePickerBorder {
  border: 1px solid #e2e8f0;
  padding: 0 5px;
  border-radius: 5px;
  margin: 0 10px !important;
}

.imageCard {
  position: relative;
  overflow: hidden;
  margin-right: 12px;
  /* height: 200px;
  width: 400px; */
}


.imageContent {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000096;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.imageCard:hover .imageContent {
  opacity: 1;
}

.EditDelete {
  display: none;
}

.CustomFieldName {
  border-bottom: 1px solid rgba(128, 128, 128, 0.705);
}

.CustomFieldName:hover .EditDelete {
  display: block;
}

.pending {
  background-color: #fbf4dd !important;
  color: #a37f08 !important;
  border: 1px solid #a37f08 !important;
}

.pending + .chakra-select__icon-wrapper svg path {
  fill: #a37f08 !important;
}

.onHold {
  background-color: #ffeeeb !important;
  color: #db5436 !important;
  border: 1px solid #db5436 !important;
}

.onHold + .chakra-select__icon-wrapper svg path {
  fill: #db5436 !important;
}

.completed {
  background-color: #eaf9e6 !important;
  color: #4d8f3a !important;
  border: 1px solid #4d8f3a !important;
}

.completed + .chakra-select__icon-wrapper svg path {
  fill: #4d8f3a !important;
}

.toDo {
  background-color: #ebf5ff !important;
  color: #1f7eeb !important;
  border: 1px solid #1f7eeb !important;
}

.toDo + .chakra-select__icon-wrapper svg path {
  fill: #1f7eeb !important;
}

.inProgress {
  background-color: #eee7fc !important;
  color: #7038db !important;
  border: 1px solid #7038db !important;
}

.inProgress + .chakra-select__icon-wrapper svg path {
  fill: #7038db !important;
}

/* Forgot password style starts */


.forgot-password-container{
  position: absolute;
  left: 37%;
  top: 20%;
}
  .heading-container{
  margin-bottom: 50px;
}
  .otp-container{
  margin-bottom: 20px;
}
/* Forgot password style ends */

/* Reset password style starts */


.reset-password-container{
  position: absolute;
  left: 37%;
  top: 20%;
}

  .reset-password-heading{
  margin-bottom: 50px;
    
}
/* Reset password style ends */



/*Signin-ui style starts*/


.checkbox-link-container{
  display:flex;
  justify-content: space-between;
}
.login-wrapper{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signup-link-container{
  display: flex;
  justify-content: center;
}
.or-div{
  width:100%; 
  text-align:center; 
  border-bottom: 1px solid #E8E8F2; 
  line-height:0.1em; 
  margin:10px 0px 40px;
}
h2 span {
  background: #fff;
  padding: 0 15px;
}
.social-signin Button {
  border: 1px solid #e8e8f2;
  border-radius: 4px;
}




/* fixes css------------- */

.css-ahxa6v{
  height: 100% !important;
}
.css-1pvrls1{
  height: 100% !important;
}
.login-wrapper{
  position: inherit;
  top: auto;
  left: auto;
  transform: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 60px;
  height: 100%;
}
.css-e9avap {
  margin-bottom: 0px !important;
}
.css-14iwkau {
  margin-bottom: 0px !important;
  margin-top: 0vh !important;
}
.social-signin button:last-child{
  margin-bottom: 0px !important;
}
.image-box .css-1bi50ss{
  height: 100%;
}
.image-box .css-40gt5p{
  height: 100%;
}
.css-4q551k{
  margin-top: 10px;
}
.sideTab{
  position: relative;
}
.hoverName{
  position: absolute;
  top: 20%;
  left: 100%;
  height: 60%;
  /* width: 100%; */
  background-color: #11047A;
  box-shadow: 0px 0px 2px 0px rgb(211, 211, 211);
  padding: 2px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  display: none;
  align-items: center;
  z-index: 999;
  white-space: nowrap;
}
.css-1xgsu60{
  overflow-x: visible !important;
}
.css-1xgsu60 > div{
  overflow: visible !important;
}
.sideTab:hover .hoverName{
  display: flex;
}
.sidebar-right .sideTab .hoverName{
  top:  100% !important;
  left:0% !important;
}
.css-tbdnd7{
  width: 100% !important;
}
.css-1ut1exn{
  overflow: visible !important;
}
.homeLogo{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 84vh;
}
.homeLogo img{
  opacity: 0.4;
}

.css-12lt4rz {
  margin-inline-end: 0px !important;
}
.css-1l2c17r{
  padding-left: 26px !important;
}
.css-80367w {
  padding-inline-start: 3px !important;
}
.sideTab.css-d8eesw .css-1n3xvm{
  -webkit-margin-end: 0px !important;
  margin-inline-end: 0px !important;
}
/* .css-1xgsu60:first-child{
  position: relative;
  z-index: 99999;
} */
/* .css-1dqtunj{
  z-index: 9999;
} */
.css-1dqtunj{
  width: auto !important;
  z-index: 1;
}
.css-1dcxbb3{
  z-index: 0;
}
.sideTab .css-1lads1q{
  gap: 10px;
}
.css-1aeuk0a, .css-dagnkm{
  cursor: pointer;
}

.css-1dqtunj .css-1xgsu60:nth-child(2) .sideTab{
  padding-bottom: 2px;
  padding-top: 5px;
}
.css-1dqtunj .css-1misahy .sideTab{
  padding-bottom: 2px;
  padding-top: 5px;
}

.css-1dqtunj .css-1xgsu60:nth-child(1) .sideTab .css-18176s2, .css-1dqtunj .css-1xgsu60:nth-child(1) .sideTab .css-id7wge{
  height: 24px;
  width: 24px;
}
.css-1dqtunj .css-1xgsu60:nth-child(2) .sideTab .css-18176s2, .css-1dqtunj .css-1xgsu60:nth-child(2) .sideTab .css-dwnsfz{
  height: 17px;
  width: 17px;
}
.css-1dqtunj .css-1misahy .sideTab .css-18176s2, .css-1dqtunj .css-1misahy .sideTab .css-dwnsfz{
  height: 17px;
  width: 17px;
}
.css-1dqtunj .css-1xgsu60:nth-child(2) .sideTab .hoverName{
  top: 16%;
  height: 70%;
}
.css-1dqtunj .css-1xgsu60:nth-child(2) .hidden-sidebar{
  display: none !important;
}
.css-1dqtunj .css-1xgsu60:nth-child(2) .css-4q551k{
  margin-top: 85px !important;
}
.css-1dqtunj .css-1misahy:last-child .css-dvxtzn{
  display: none !important;
}
.css-1dqtunj .css-1misahy:last-child .css-4q551k{
  margin-top: 85px !important;
}

.lead-table{
  padding: 0px !important;
}
.lead-table .lead-table-header{
  border-bottom: 1px #e8e8e8 solid;
  padding: 16px 16px 0px;
}
.export-btn{
  padding: 8px 16px;
  border: 1px #E9EBEC solid;
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
}
.advance-search-button{
  padding: 8px 16px;
  border: 1px #E9EBEC solid !important;
  border-radius: 4px !important;
  height: 40px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16.94px;
  color: #333335 !important;
}
input{
  font-weight: 400 !important;
}
.search-input {
  margin: 0px !important;
}
.search-input .chakra-input__left-element{
  height: 40px;
  top: 1px;
  z-index: 99;
}
.search-input input{
  border: none;
  height: 40px;
  border-radius: 4px;
  background: #F4F3F9;
}
.add-new-btn{
  height: 40px !important;
  border-radius: 4px !important;
  background-color:#6560F0 !important ;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.lead-table-header .chakra-text{
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.lead-table-main tbody tr td p{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #333335;
}
table tbody tr td p{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #333335;
}
.lead-table-main tbody tr td{
  padding: 12px 1.5rem !important;
  border-bottom: 1px #e9ebec solid;
}
.lead-table-main thead tr th span{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #333335;
}
table thead tr th span{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #333335;
}
.lead-table-main .chakra-select{
  border: none !important;
  height: 35px !important;
}
.lead-table-main .chakra-menu__menu-button svg{
  font-size: 18px !important;
}
.lead-table{
  border-radius: 4px !important;
}
.chakra-menu__menu-list button{
  font-size: 14px;
}
.modal-main{
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  transform: translate(-50%, -50%) !important;
  max-width: 40% !important;
  border-radius: 8px;
}
.custom-nav{
  background-color: #111c43;
}
.header .header-left-logo{
  margin: 0px;
  background: #111c43;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.299s;
}
.header .header-left-logo.open-sidebar{
  width: 220px;
  /* transition: .6s; */
}
.header .css-1mvnw1d h2{
  width: auto !important;
  height: auto !important;
  color: #fff;
}
.header .css-967svn{
  padding-top: 0px;
  padding-bottom: 0px;
  border: none;
  margin: 0px;
  padding-inline-start: 6px;
}
.main-sidebar .css-1misahy:last-child{
  width: 100vw !important;
  height: 46px !important;
  margin-top: 80px !important;
}
.main-sidebar .css-1misahy:first-child{
  width: 80px !important;
  height: 100vh !important;
  margin-top: 0px !important;
}
.main-sidebar .css-1misahy:last-child .css-4q551k {
  margin-top: -3px !important;
}
.main-sidebar .css-1misahy:last-child .css-4q551k > .css-0{
  display: flex !important;
}
.main-sidebar .css-1misahy:first-child .css-4q551k > .css-0{
  display: block !important;
}
.main-sidebar .sidebar-right{
  width: 100vw !important;
  height: 42px !important;
  margin-top: 80px !important;
  min-height: 0 !important;
}
.main-sidebar .left-sidebar1 {
  width: 80px !important;
  height: 100vh !important;
  margin-top: 0px !important;
}
.main-sidebar .css-1xgsu60:last-child .css-4q551k > .css-0{
  display: flex;
}
.main-sidebar .css-1xgsu60:first-child .css-4q551k > .css-0{
  display: block;
}
.main-sidebar .css-1xgsu60:nth-child(2) .css-4q551k {
  margin-top: -7px !important;
}
.main-sidebar {
  z-index: 1 !important;
  min-height: auto !important;
  height: 6vh !important;
}
.css-1dcxbb3 {
  width: calc(100% - 80px) !important;
  max-width: 100% !important;
}

.main-content{
  padding-top: 145px !important;
}
.css-9s0ta2 .css-wxiio1{
  padding-top: 145px !important;
}
.header .css-dagnkm {
  margin-inline-start: var(--chakra-space-3) !important;
}
.main-sidebar .css-1misahy .css-1ut1exn{
  overflow-x: scroll !important;
}
.main-sidebar .css-1misahy .css-1ut1exn::-webkit-scrollbar {
  display: none;
}
.main-sidebar .css-1misahy .css-1ut1exn {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main-sidebar .css-1misahy .css-1ut1exn{
  margin-bottom: 0px !important;
}
.sidebar-right .tab-linkname{
  display: none;
}
.left-sidebar1 .sideTab .left-link{
  display: flex;
  gap: 12px;
}
.left-sidebar1 .tab-linkname{
  display: none;
}
.left-sidebar1.showlinkName .tab-linkname{
  display: block !important;
}
.main-sidebar .left-sidebar1{
  transition: 0.299s;
}
.main-sidebar .left-sidebar1.showlinkName{
  width: 220px !important;
  /* transition: 0.299s; */
}
.main-sidebar .sidebar-right .linktext{
  display: none !important;
}
.user-img img{
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: lightgrey;
}
.user-info h5{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #333335;
}
.user-info h6{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #8c90b2;
}
.notification-icon{
  color: #333335 !important;
}
.user-img img{
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: lightgrey;
}
.user-info h5{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #333335;
}
.user-info h6{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: #8c90b2;
}
.notification-icon{
  color: #333335 !important;
}
.notification-box{
  position: relative;
  margin-right: 16px;
}
.notification-box .count-noti{
  position: absolute;
  top: 5px;
  right: 0px;
  background-color: rgb(176, 0, 0);
  color: #fff;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.css-zj2dqk {
  top: 38% !important;
}
.theme-btn1 {
  padding: 12px 16px !important;
  height: 40px !important;
  border-radius: 4px !important;
  background-color: #6560f0 !important ;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.theme-btn2{
  padding: 12px 16px !important;
  height: 40px !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.theme-btn3 {
  padding: 12px 16px !important;
  height: 40px !important;
  border-radius: 4px !important;
  background-color: #E6533C !important ;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.left-link.cus-das svg {
  margin-top: -9px;
}
.custom-select{
  position: relative;
}
.select{
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 16px;
  justify-content: space-between;
  cursor: pointer;
  /* min-width: 130px; */
  gap: 4px;
  text-transform: capitalize;
}
.select.active {
  background-color: #26bf941a !important;
  color: #26bf94 !important;
}
.select.sold {
  background-color: #e6533c1a !important;
  color: #e6533c !important;
}
.select.pending {
  background-color: #f5b8491a !important;
  color: #f5b849 !important;
  border: none !important;
}
.active-svg path {
  stroke: #26bf94 !important;
}
.sold-svg path {
  stroke: #e6533c !important;
}
.pending-svg path {
  stroke: #f5b849 !important;
}
.chipIconContainer {
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.options {
  position: absolute;
  top: 102%;
  left: 0%;
  width: 100%;
  background-color: #f6fff3;
  border: 1px #c8fcba solid;
  z-index: 999;
    display:none;
  border-radius: 4px;
  min-width: 100px;
}
.options ul{
  margin: 0px;
  padding: 0px;
}
.options ul li{
  list-style: none;
  margin: 0px;
  padding: 6px 12px;
  font-size: 14px;
  border-bottom: 1px rgb(233, 233, 233) solid;
  cursor: pointer;
}
.options ul li:last-child{
  border: none;
}
.custom-select:hover .options{
  display: block;
}
  .chakra-portal[data-aria-hidden='true'] .header .css-967svn{
  padding-inline-start: 0px !important;
  transition: none !important;
}
  body[data-scroll-locked="1"] .header .css-967svn{
  padding-inline-start: 0px !important;
  transition: none !important;
}
  .cus-select{
  padding: 0px 12px 0px 14px;
  height: 40px !important;
}
@media screen and (min-width: 1200px) {
  .css-9s0ta2 {
    width: calc(100% - 358px) !important;
    max-width: calc(100% - 358px) !important;
  }
  
}
@media screen and (min-width: 960px) {
  .custom-nav .css-1ut1exn {
    -webkit-margin-end: 0px !important;
    margin-inline-end: 0px !important;
  }
  .css-1xgsu60 .css-1ut1exn{
    -webkit-margin-end: 0px !important;
    margin-inline-end: 0px !important;
  }
  .header .css-967svn{
    padding-inline-start: 6px;
  }
}
.css-9s0ta2 {
  width: calc(100% - 220px) !important;
  max-width: calc(100% - 80px) !important;
}

  @media(max-width:1024px){
    .signin-box{
    height: 100vh !important;
  }
  .css-1pvrls1 {
    max-width: 80% !important;
  }
  .dash-cont {
    width: calc(100% - 0px) !important;
    max-width: 100% !important;
  }
  .header .css-967svn {
    padding-inline-start: 0px;
  }
  .tabhide {
    display: none !important;
  }
  .header-left-logo h2{
    width: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .css-ispbx6 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  .main-content {
    padding-top: 106px !important;
  }
  .sidebar-logo{
    font-size: 22px !important;
  }
  .sidebar-overflow{
    height: 78vh;
    overflow-y: scroll;
  }
  .hide-logo{
    display: none !important;
  }
  .modal-main {
    max-width: 80% !important;
  }
}
@media screen and (max-width: 820px) {
  .css-ib68do {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
@media screen and (max-width: 768px) {
  .signin-box {
    width: 100vw;
    overflow-y: auto;
  }
  .login-wrapper {
    width: 100vw !important;
  }
  .image-box {
    display: none;
  }
  .css-1pvrls1 {
    max-width: 100% !important;
    width: 100vw !important;
  }
  .login-wrapper {
    width: 90vw;
  }
  .signin-box {
    height: auto;
  }
}
/*Signin-ui style ends*/

@media (max-width: 767px) {
  .css-1pvrls1 {
    width: 100% !important;
  }
  .login-wrapper {
    width: 100% !important;
  }
  .css-ispbx6 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .css-ib68do {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  .css-yipamz {
    flex-direction: row !important;
  }
  .css-1u2cmhg {
    justify-content: start !important;
    flex-wrap: wrap !important;
  }
  .modal-main {
    max-width: 96% !important;
  }
}

/* .sidebar-right .top-menu-container{
    margin-right: 80px;
  }

  .sidebar-right .top-menu{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .sidebar-right .topMenu-right{
    margin-right: 24px !important;
  }

  .sidebar-right .top-menu-scroller{
    overflow: auto !important;
  } */
.profileBtn {
  margin-right: 16px !important;
}
.topMenu-right {
  margin-right: 24px !important;
}

.user-info {
  text-align: left;
}

.removeBorder {
  border: 0 !important;
}

.linkGap {
  gap: 10px;
}

.chakra-select__wrapper {
  width: auto !important;
}

.chakra-select__icon-wrapper {
  top: 50% !important;
}

.flexEndColumn {
  display: flex;
  justify-content: end;
}

.actionHeaderMargin {
  margin-right: 20px;
}

.headerMargin {
  margin-right: 8px;
}

.chakra-modal__header {
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 28px;
  padding: 24px !important;
}

.closeIcon svg path {
  fill: #8c90b2 !important;
}

.closeIcon {
  position: absolute !important;
  top: 16px;
  right: 16px;
}

.cancelBtn {
  color: #8c90b2 !important;
  border-color: #e9ebec !important;
}

.customSearchIcon path {
  fill: #8c90b2 !important;
}

.search-input input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.paginationContainer {
  padding: 16px;
}

.pageContainer {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: end;
}

.paginationMsg {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  letter-spacing: 0%;
  text-align: right;
  color: #333335;
}

.paginationMsg span{
  font-weight: 500 !important;
}

.paginationBtn{
  border-radius: 4px !important;
}

.paginationControllerBtn{
  border: 1px solid #E9EBEC;
}

.alignSelectIcon .chakra-select__icon-wrapper{
top: 40% !important;
}